<script>
import { Bar } from 'vue-chartjs'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'

export default {
  extends: Bar,
  props: ['height'],
  data: function () {
    return {
      datasetsApi : [
        {
          label: 'Testing A, Only Annotation, Manual',
          backgroundColor: 'rgba(255,255,255,.3)',
          borderColor: 'transparent',
          data: [],
          labels: []
        },
        {
          label: 'Testing A, Only Annotation, Machine',
          backgroundColor: 'rgba(255,0,255,.2)',
          borderColor: 'transparent',
          data: [],
          labels: []
        },
        {
          label: 'Testing B, Only Annotation, Manual',
          backgroundColor: 'rgba(255,255,255,.9)',
          borderColor: 'transparent',
          data: [],
          labels: []
        },
        {
          label: 'Testing B, Only Annotation, Machine',
          backgroundColor: 'rgba(255,0,255,.12)',
          borderColor: 'transparent',
          data: [],
          labels: []
        },
      ],
      trainingSelected : {},
      resultTestingObject: {},

    }
  },
  mounted () {

    this.trainingSelected  = (this.$store.getters['dashboard/training']) ? this.$store.getters['dashboard/training'] : {}
    this.resultTestingObject = JSON.parse(this.trainingSelected.result_testing)

    let labelTemp = []
    for (let item of Object.keys(this.resultTestingObject.testing.onlyAnnotation.annotation)) {
        this.datasetsApi[0].data.push(this.resultTestingObject.testing.onlyAnnotation.annotation[item])
        this.datasetsApi[1].data.push(this.resultTestingObject.testing.onlyAnnotation.testing[item])
        if (this.resultTestingObject.testing_b ){
          if (this.resultTestingObject.testing_b.onlyAnnotation){
            this.datasetsApi[2].data.push(this.resultTestingObject.testing_b.onlyAnnotation.annotation[item])
            this.datasetsApi[3].data.push(this.resultTestingObject.testing_b.onlyAnnotation.testing[item])
          }
        }
        labelTemp.push(item)
    }
        this.renderChart(
          {
            labels:labelTemp,
            datasets: this.datasetsApi
          },
          {
            tooltips: {
              enabled: false,
              custom: CustomTooltips
            },
            maintainAspectRatio: false,
            legend: {
              display: true
            },
            scales: {
              xAxes: [{
                display: false,
                categoryPercentage: 1,
                barPercentage: 0.5
              }],
              yAxes: [{
                display: true
              }]
            }
          }
        )

  }
}
</script>

